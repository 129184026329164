.Landing{
    height: 100vh;
    width: 100vw;
    background-color: #F3F4EF;
    display: flex;
}

.Landing_LeftContainer{
    flex-basis: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 120px 24px 84px;
}

.Landing_FooterContainer{
    position: absolute;
    bottom: 84px;
    color: #46237A !important;
}

.Landing_FooterLink{
    color: #46237A !important;
    text-decoration: underline !important;
    margin: 6px;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
}

.Landing_TaglineContainer{
    /* background-color: yellow; */
    position: absolute;
    top: 84px
}

.Landing_TaglineText{
    font-size: 16px;
    font-weight: 500;
}

.Landing_CTAContainer{
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 500px;
}

.Landing_HeadingText{
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0 !important;
    font-size: 72px !important;
}

.Landing_SubheadingText{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.Landing_SubheadingText_Emphasized{
    /* font-weight: bold; */
    font-style: italic;
    font-weight: 500;
}

.Landing_JoinWaitlistForm{
    height: 48px;
    margin-top: 60px;
}

.Landing_EmailInput{
    border: none;
    width: 350px;
}

.Landing_SubmitButton{
    height: 48px;
     background-color: #46237A;
     font-weight: bold;
    font-family: "Montserrat", sans-serif !important;

}

.Landing_SubmitButton:hover{
    background-color: #8e61d1 !important;
}

.Landing_RightContainer{
    flex-basis: 60%;
    display: flex;
    justify-content: flex-start;
}

.Landing_ThankYouContainer{
    margin-top: 60px;
    background-color: white;
    padding: 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.Landing_FormSubmissionErrorAlert{
    margin-top: 12px;
}

.Landing_ThankYouHeadlineText{
    font-size: 24px !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.Landing_ThankYouBodyText{
    font-size: 16px;
    margin-top: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.Landing_Mobile_BottomContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.Landing_Mobile{
    height: 100vh;
    width: 100vw;
    background-color: #F3F4EF;
    display: flex;
    flex-direction: column;
}

.Landing_Mobile_HeaderContainer{
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Landing_Mobile_HeaderContainer_PaddingTop36{
    padding: 24px;
}

@media (max-width: 768px) {
    .Landing_SubheadingText{
        font-size: 18px;
        margin-top: 12px;
        font-family: "Montserrat", sans-serif;
    }

    .Landing_HeadingText{
        font-size: 48px !important;
    }

    .Landing_HeadingText_MobileNoMargin{
        margin-top: 0;
    }

    .Landing_SubmitButton{
        width: 100%;
    }

    .Landing_JoinWaitlistForm{
        padding: 24px;
        height: auto;
    }

    .Landing_EmailInput{
        height: 48px;
        width: 100%;
        margin-bottom: 0px;
    }

    .Landing_EmailInputFormItem{
        margin-bottom: 12px;
    }

    .Landing_JoinWaitlistForm{
        margin-top: 24px;
        width: 100%;
    }

    .Landing_ThankYouContainer{
        margin: 24px 24px 12px 24px;
    }

    .Landing_ThankYouHeadlineText{
        font-size: 16px !important;
        font-weight: bold;
    }

    .Landing_ThankYouBodyText{
        font-size: 14px;
    }

    .Landing_FooterContainer{
        position: unset;
        /* bottom: 24px; */
    }
}
