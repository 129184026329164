.ScrollableFragranceCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-gap: 20px; /* Space between cards */
    height: 100%; /* Ensure it takes up the full height available */
    width: 100%;
    max-width: 800px; /* Adjust based on your design */
    pointer-events: none; /* Disable user interaction (e.g., manual scrolling) */
    overflow-y: scroll; /* Ensure it scrolls vertically */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.ScrollableFragranceCards::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }


  @media (max-width: 768px) {
    .ScrollableFragranceCards {
        display: flex;
        flex-direction: row; /* Make the flex items (cards) flow horizontally */
        gap: 20px; /* Space between cards */
        height: fit-content; /* Ensure it takes up the full height available */
        width: 100%;
        max-width: 100%; /* Take up full width of the container */
        pointer-events: none; /* Disable user interaction (e.g., manual scrolling) */
        overflow-x: auto; /* Scroll horizontally */
        scrollbar-width: none; /* Hide scrollbar for Firefox */
        -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
    }
    
    .ScrollableFragranceCards::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    
  }