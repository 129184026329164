.PrivacyPolicy {
    min-height: 100vh; /* Ensure the container takes up full screen height */
    width: 100vw;
    background-color: #F3F4EF;
    display: flex;
    justify-content: center;
    padding: 60px 24px; /* Padding for top and bottom space */
    box-sizing: border-box;
    overflow-y: auto; /* Scrollbar on the outer container */
}

.PrivacyPolicy_ContentContainer {
    width: 800px;
    max-width: 100%;
    margin: auto; /* Center horizontally */
    padding-bottom: 60px; /* Extra padding at the bottom */
    box-sizing: border-box;
}
