.ScrollableFragranceCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 400px;
    position: relative;
    border-radius: 4px;
}

.ScrollableFragranceCard_ImageContainer{
    width: 250px;
    height: 400px;
    background-color: white;
    display: flex;
    position: relative; /* Add this to position the gradient overlay properly */
    justify-content: center;
    align-items: center;
}

.ScrollableFragranceCard_Image{
    width: 100px;
    height: 200px;
    background-size: cover; /* Ensure the entire image fits within the container */
    background-position: center; /* Centers the image within the container */
    background-repeat: no-repeat; 
    background-image: var(--img);
    border-radius: 4px;
    position: relative; /* Ensure the image is positioned properly within the container */
}


.ScrollableFragranceCard_GradientOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(135deg, rgba(255, 120, 90, 0.2) 0%, rgba(108, 105, 141, 0.5) 100%); */
    /* background: radial-gradient(circle, hsla(18, 82%, 89%, 0.4) 30%, hsla(233, 57%, 88%, 0.4) 70%); */
    background: linear-gradient(135deg, hsla(18, 82%, 89%, 0.5) 0%, rgba(108, 105, 141, 0.3) 100%);
    /* background: radial-gradient(circle, hsla(197, 100%, 63%, .3) 0%, hsla(264, 55%, 60%, .3) 100%); */
    border-radius: 4px;
    pointer-events: none; /* Ensure the overlay doesn't interfere with interactions */
}

.ScrollableFragranceCard_SearchTextContainer{
    width: 100%; /* Ensures the container takes the full width of the card */
    padding: 12px; /* Keeps the padding for spacing inside the container */
    box-sizing: border-box; /* Ensures padding doesn't affect the width */
    border-radius: 4px;
    background-color: white;
    margin-top: 12px; /* You can keep this if you need spacing from the element above */
}

.ScrollableFragranceCard_SearchText{
    text-align: center;
    width: 100%; /* Ensures the text takes up the full width of the container */
}

/* Staggering the middle column */
.ScrollableFragranceCard:nth-child(3n + 2) {
    position: relative;
    top: 250px; /* Adjust the offset as needed to stagger the middle column */
}



@media (max-width: 768px) {
    .ScrollableFragranceCard:nth-child(3n + 2) {
        position: relative;
        top: 0; /* Adjust the offset as needed to stagger the middle column */
    }
    
    .ScrollableFragranceCard{
        height: fit-content;
    }

    .ScrollableFragranceCard_ImageContainer{
        height: auto;
        min-height: 300px;
    }
}

/* @media (max-width: 576px) {
    .ScrollableFragranceCard_ImageContainer{
        min-height: 350px;
    }
} */